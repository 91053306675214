import React from "react";
import PropTypes from "prop-types";

const StyledAnchor = ({ children, disabled, href, newTab }) => {
  return (
    <a
      href={disabled ? " " : href}
      target={newTab ? "_blank" : null}
      style={{ textDecoration: "none" }}
    >
      {children}
    </a>
  );
};

StyledAnchor.propTypes = {
  href: PropTypes.string
};

export default StyledAnchor;
