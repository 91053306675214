// @flow
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import { createHashHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers';
import { initSagas } from './rootSaga';

const sagaMiddleware = createSagaMiddleware();
const history = createHashHistory();
const rootReducer = createRootReducer(history);
const router = routerMiddleware(history);
const enhancer = applyMiddleware(thunk, router, sagaMiddleware);

function configureStore(initialState) {
  const store =  createStore(rootReducer, initialState, enhancer);
  initSagas(sagaMiddleware);
  return store;
}
export default {
  configureStore,
  history
};
