import React, { useEffect } from "react";
import _ from "lodash";
import deburr from "lodash/deburr";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import AutoSuggestTextFieldStyles from "./AutoSuggestTextFieldStyles";
import { Search } from "@material-ui/icons";
import { InputAdornment } from "@material-ui/core";

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      variant="outlined"
      fullWidth
      placeholder="Search Exchange..."
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);
  return (
    <MenuItem selected={isHighlighted} component="div">
      {parts.map(part => (
        <span
          key={part.text}
          style={{ fontWeight: part.highlight ? 900 : 400 }}
        >
          {part.text}
        </span>
      ))}
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

function AutoSuggestTextField({
  classes,
  items,
  createSuggestionsItems,
  filterSuggestions,
  clearRequested,
  customRenderSuggestion
}) {
  const [suggestions, setSuggestions] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    single: "",
    popper: ""
  });

  const createSuggestions = () => {
    if (createSuggestions) {
      const newSuggestions = _.map(items, item => {
        return { ...item, label: item.name };
      });
      setSuggestions(newSuggestions);
    } else {
      const newSuggestions = _.map(items, item => {
        return { label: item };
      });
      setSuggestions(newSuggestions);
    }
  };

  useEffect(() => {
    if (items && items.length > 0) {
      createSuggestions();
    }
  }, [items]);

  function getSuggestions(value) {
    const inputValue = deburr(value.trim());

    if (filterSuggestions) {
      return filterSuggestions(suggestions, inputValue);
    }

    return _.filter(suggestions, suggestion => {
      const keep = _.includes(suggestion.label, inputValue);
      return keep;
    });
  }

  const [stateSuggestions, setStateSuggestions] = React.useState([]);

  const handleSuggestionsFetchRequested = ({ value }) => {
    setStateSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setStateSuggestions([]);
  };

  const handleChange = name => (event, { newValue }) => {
    setState({
      ...state,
      [name]: newValue
    });
    // Elevate when input has been cleared
    if (!newValue || newValue.length === 0) {
      if (clearRequested) {
        clearRequested();
      }
    }
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion: customRenderSuggestion
      ? customRenderSuggestion
      : renderSuggestion
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          // placeholder: "Search...",
          value: state.popper,
          onChange: handleChange("popper"),
          inputRef: node => {
            setAnchorEl(node);
          },
          InputLabelProps: {
            shrink: true
          }
        }}
        theme={{
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Popper
            style={{ zIndex: 100000000 }}
            anchorEl={anchorEl}
            open={Boolean(options.children)}
          >
            <Paper
              square
              {...options.containerProps}
              style={{ width: anchorEl ? anchorEl.clientWidth : undefined }}
            >
              {options.children}
            </Paper>
          </Popper>
        )}
      />
    </Paper>
  );
}

export default withStyles(AutoSuggestTextFieldStyles)(AutoSuggestTextField);
