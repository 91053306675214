import React from "react";
import citationImage from "../../../resources/images/Citation_v2.svg";

const CitationIcon = ({ size = 30 }) => {
  return (
    <img
      src={citationImage}
      alt="Citation-Icon"
      style={{
        minWidth: size,
        maxWidth: size,
        maxHeight: size,
        minHeight: size,
        padding: 6
      }}
    />
  );
};

export default CitationIcon;
