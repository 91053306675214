import { filterPluginsByCategoryIdsAndApplicationIds } from "../../utils/ExchangeUtils";

const SET_FILTERED_PLUGINS = "SET_FILTERED_PLUGINS";
const RESET_FILTERED_PLUGINS = "RESET_FILTERED_PLUGINS";
const SET_FILTERED_CATEGORIES = "SET_FILTERED_CATEGORIES";
const RESET_FILTERED_CATEGORIES = "RESET_FILTERED_CATEGORIES";
const SET_FILTERED_APPLICATIONS = "SET_FILTERED_APPLICATIONS";
const RESET_FILTERED_APPLICATIONS = "RESET_FILTERED_APPLICATIONS";
const TOGGLE_SHOW_FEATURED_PLUGINS = "SET_SHOW_FEATURED_PLUGINS";
const SET_SELECTED_APPLICATION = "SET_SELECTED_APPLICATION";

///////////////////////////////////////////////////////////////////////////////

export const setFilteredApplications = filteredApplicationsIds => (
  dispatch,
  getState
) => {
  const { exchange, filtered } = getState();
  const { plugins, categories } = exchange;
  const { filteredCategories } = filtered;

  const selectedCategories =
    filteredCategories && filteredCategories.length > 0
      ? filteredCategories
      : categories;

  const newFilteredPlugins = filterPluginsByCategoryIdsAndApplicationIds(
    plugins,
    selectedCategories,
    filteredApplicationsIds
  );

  return dispatch({
    type: SET_FILTERED_APPLICATIONS,
    payload: {
      filteredApplications: filteredApplicationsIds,
      filteredPlugins: newFilteredPlugins
    }
  });
};

export const setSelectedApplication = applicationId => (dispatch, getState) => {
  const { exchange, filtered } = getState();
  const { plugins, categories } = exchange;
  const { filteredCategories } = filtered;

  const selectedCategories =
    filteredCategories && filteredCategories.length > 0
      ? filteredCategories
      : categories;
  const selectedApplications = [applicationId];

  const newFilteredPlugins = filterPluginsByCategoryIdsAndApplicationIds(
    plugins,
    selectedCategories,
    selectedApplications
  );

  return dispatch({
    type: SET_SELECTED_APPLICATION,
    payload: {
      selectedApplication: applicationId,
      filteredPlugins: newFilteredPlugins
    }
  });
};

export const resetFilteredApplications = () => dispatch => {
  return dispatch({
    type: RESET_FILTERED_APPLICATIONS
  });
};

export const toggleShowFeaturedPlugins = () => (dispatch, getState) => {
  const { filtered } = getState();
  const { showFeaturedPlugins } = filtered;
  return dispatch({
    type: TOGGLE_SHOW_FEATURED_PLUGINS,
    payload: !showFeaturedPlugins
  });
};
///////////////////////////////////////////////////////////////////////////////

export const setFilteredCategories = filteredCategories => (
  dispatch,
  getState
) => {
  const { exchange, filtered } = getState();
  const { plugins, applications } = exchange;
  const { filteredApplications } = filtered

  const selectedApplications =
    filteredApplications && filteredApplications.length > 0
      ? filteredApplications
      : applications;

  const newFilteredPlugins = filterPluginsByCategoryIdsAndApplicationIds(
    plugins,
    filteredCategories,
    selectedApplications
  );

  return dispatch({
    type: SET_FILTERED_CATEGORIES,
    payload: { filteredCategories, filteredPlugins: newFilteredPlugins }
  });
};

export const resetFilteredCategories = () => dispatch => {
  return dispatch({
    type: RESET_FILTERED_CATEGORIES
  });
};
///////////////////////////////////////////////////////////////////////////////

export const setFilteredPlugins = filteredPlugins => dispatch => {
  return dispatch({
    type: SET_FILTERED_PLUGINS,
    payload: filteredPlugins
  });
};

export const resetFilteredPlugins = () => dispatch => {
  return dispatch({
    type: RESET_FILTERED_PLUGINS
  });
};

///////////////////////////////////////////////////////////////////////////////
const initialState = {
  filteredPlugins: null,
  filteredCategories: [],
  filteredApplications: [],
  selectedApplication: null,
  showFeaturedPlugins: true
};

const filtered = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_APPLICATION:
      return {
        ...state,
        selectedApplication: action.payload.selectedApplication,
        filteredPlugins: action.payload.filteredPlugins
      };
    case TOGGLE_SHOW_FEATURED_PLUGINS:
      return {
        ...state,
        showFeaturedPlugins: action.payload
      };
    case SET_FILTERED_PLUGINS:
      return {
        ...state,
        filteredPlugins: action.payload
      };
    case RESET_FILTERED_PLUGINS:
      return {
        ...state,
        filteredPlugins: []
      };
    ///////////////////////////////////////////////////////////////////////////////
    case SET_FILTERED_CATEGORIES:
      return {
        ...state,
        filteredCategories: action.payload.filteredCategories,
        filteredPlugins: action.payload.filteredPlugins
      };
    case RESET_FILTERED_CATEGORIES:
      return {
        ...state,
        filteredCategories: []
      };
    ///////////////////////////////////////////////////////////////////////////////
    case SET_FILTERED_APPLICATIONS:
      return {
        ...state,
        filteredApplications: action.payload.filteredApplications,
        filteredPlugins: action.payload.filteredPlugins
      };
    case RESET_FILTERED_APPLICATIONS:
      return {
        ...state,
        filteredApplications: []
      };
    default:
      return state;
  }
};
///////////////////////////////////////////////////////////////////////////////
export default filtered;
///////////////////////////////////////////////////////////////////////////////
