import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { PATHS } from "../../../routes/Routes";
import StyledLink from "../StyledLink";
import BreadCrumbLoader from "./BreadCrumbLoader";

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  }
});

function Breadcrumb({ classes, current }) {
  return (
    <Paper elevation={0} className={classes.root}>
      <Breadcrumbs aria-label="Breadcrumb">
        <StyledLink to={PATHS.EXCHANGE}>Exchange</StyledLink>
        {current ? <Typography>{current}</Typography> : <BreadCrumbLoader />}
      </Breadcrumbs>
    </Paper>
  );
}

Breadcrumb.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Breadcrumb);
