import _ from "lodash";
import {
  HashTable
} from "./HashTable";
import ExchangePlugin from "../constants/ML/ExchangePlugin";
import {
  getDayMonthYear
} from './DateUtils';

export const getPluginsByCategory = (plugins, categoryId) => {
  const pluginsByCategory = _.filter(plugins, plugin => {
    const {
      categories
    } = plugin;
    const pluginCategories = _.map(categories, "id");
    return getItemById(pluginCategories, categoryId);
  });

  if (!pluginsByCategory || pluginsByCategory.length === 0) {
    return null;
  }

  return pluginsByCategory;
};

export const getItemById = (items, id) => {
  return _.find(items, function (o) {
    return Number.parseInt(o.id) === Number.parseInt(id);
  });
};

export const getLatestPluginVersion = versions => {
  try {
    const parsedVersions = JSON.parse(versions);
    return parsedVersions[0];
  } catch (e) {
    return versions[0];
  }
};

export const getLatestPluginVersionNumber = versions => {
  return getLatestPluginVersion(versions).version;
};

export const getLatestPluginVersionPublishDate = versions => {
  return getLatestPluginVersion(versions).publishDate;
};

export const getLatestPluginVersionNumberAndPublishDate = versions => {
  const latestVersion = getLatestPluginVersion(versions)
  return `v${latestVersion.version} published ${getDayMonthYear(latestVersion.publishDate)}`;
};

export const getLatestPluginVersionLink = versions => {
  return getLatestPluginVersion(versions).downloadLink;
};

export const getCategorizedPluginDict = plugins => {
  const categorizedPluginsDict = new HashTable();
  _.map(plugins, plugin => {
    const {
      categories
    } = plugin;
    if (categories && categories[0] && categories[0].name) {
      const categoryName = categories[0].name;
      if (categorizedPluginsDict.hasItem(categoryName)) {
        categorizedPluginsDict.getItem(categoryName).push(plugin);
      } else {
        const newCategoryArray = [plugin];
        categorizedPluginsDict.setItem(categoryName, newCategoryArray);
      }
    }
  });

  return categorizedPluginsDict;
};

function toMiniscule(item) {
  return item.name.toLowerCase();
}

export const filterPluginsByApplication = (plugins, application) => {
  return _.filter(plugins, plugin => {
    const {
      applications
    } = plugin;
    const applicationsLC = _.map(applications, toMiniscule);
    return _.find(applicationsLC, function (o) {
      return _.includes(o, application.toLowerCase());
    });
  });
};

export const filterPluginsByApplications = (plugins, applications) => {
  return _.filter(plugins, plugin => {
    const {
      applications
    } = plugin;
    const applicationsLC = _.map(applications, toMiniscule);
    return _.find(applicationsLC, function (o) {
      return _.includes(o, applications);
    });
  });
};

export const filterPluginsByCategoryIds = (plugins, filteredCategories) => {
  return _.filter(plugins, plugin => {
    const categoryIds = _.map(plugin.categories, function (item) {
      return item.id;
    });
    return _.find(categoryIds, r => !_.includes(filteredCategories, r));
  });
};

export const filterPluginsByApplicationIds = (
  plugins,
  filteredApplications
) => {
  return _.filter(plugins, plugin => {
    const applicationIds = _.map(plugin.applications, function (item) {
      return item.id;
    });
    return _.find(applicationIds, r => !_.includes(filteredApplications, r));
  });
};

export const getApplicationIds = applications => {
  return _.map(applications, function (item) {
    return item.id;
  });
};

export const getCategoryIds = categories => {
  return _.map(categories, function (item) {
    return item.id;
  });
};

export const filterPluginsByCategoryIdsAndApplicationIds = (
  plugins,
  filteredCategories,
  filteredApplications
) => {
  const filteredPluginsByApplication = filterPluginsByApplicationIds(
    plugins,
    filteredApplications
  );
  const filteredPluginsByCategories = filterPluginsByCategoryIds(
    filteredPluginsByApplication,
    filteredCategories
  );
  return filteredPluginsByCategories;
};

///////////////////////////////////
export const parseExchangePlugins = plugins => {
  const newRefactoredPlugins = _.map(plugins, plugin => {
    const pluginKeys = _.keys(plugin);
    const newPlugin = plugin;
    _.map(pluginKeys, key => {
      const pluginKey = plugin[key];
      try {
        if (pluginKey === "true" || pluginKey === "false") {
          newPlugin[key] = pluginKey === "true";
        } else if (!Number.isInteger(pluginKey)) {
          newPlugin[key] = _.values(JSON.parse(pluginKey));
        }
      } catch (e) {}
    });
    return newPlugin;
  });
  return _.sortBy(newRefactoredPlugins, [
    ExchangePlugin.NUM_DOWNLOADS
  ]).reverse();
};

export const getSortedPluginVersions = (versions) => {
  return versions.sort((a, b) => a.version.localeCompare(b.version, undefined, { numeric: true, sensitivity: 'base' })).reverse();
}