import React from "react";
import FlowJoImage from "../../../resources/images/FlowJo_Exchange_Logo.svg";

const FlowJoExchangeIcon = ({ width = 450, height = 30 }) => {
  return (
    <img
      src={FlowJoImage}
      alt="FlowJo-Exchange-Icon"
      style={{
        minWidth: width,
        maxWidth: width,
        // maxHeight: height,
        // minHeight: height,
        padding: 4
      }}
    />
  );
};

export default FlowJoExchangeIcon;
