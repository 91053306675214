import { connect } from "react-redux";
import PluginFilters from "./PluginFilters";
import {
  resetFilteredPlugins,
  setFilteredApplications,
  resetFilteredApplications,
  setFilteredCategories,
  resetFilteredCategories,
  toggleShowFeaturedPlugins,
  setSelectedApplication,
  setFilteredPlugins
} from "../../../../store/reducers/filtered";

const mapStateToProps = ({
  exchange: { plugins, categories, applications },
  filtered: {
    filteredCategories,
    filteredApplications,
    showFeaturedPlugins,
    selectedApplication
  }
}) => ({
  plugins,
  categories,
  applications,
  filteredCategories,
  filteredApplications,
  showFeaturedPlugins,
  selectedApplication
});

const mapDispatchToProps = dispatch => {
  return {
    setFilteredPlugins: filteredPlugins =>
      dispatch(setFilteredPlugins(filteredPlugins)),
    resetFilteredPlugins: () => dispatch(resetFilteredPlugins()),
    setFilteredCategories: filteredCategories =>
      dispatch(setFilteredCategories(filteredCategories)),
    resetFilteredCategories: () => dispatch(resetFilteredCategories()),
    setFilteredApplications: filtedApplications =>
      dispatch(setFilteredApplications(filtedApplications)),
    resetFilteredApplications: () => dispatch(resetFilteredApplications()),
    toggleShowFeaturedPlugins: () => dispatch(toggleShowFeaturedPlugins()),
    setSelectedApplication: applicationId =>
      dispatch(setSelectedApplication(applicationId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PluginFilters);
