import { makeStyles } from "@material-ui/core/styles";

const usePluginCategoryStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  title: {},
  titleItem: {
    maxWidth: theme.spacing(4)
  },
  grid: {},
  breadcrumb: {
    width: "100%"
  },
  content: {
    // padding: theme.spacing(4)
  }
}));

export default usePluginCategoryStyles;
