const AutoSuggestTextFieldStyles = theme => ({
  root: {
    maxHeight: 60,
    // minWidth: 200,
    flexGrow: 1
  },
  container: {
    // position: "relative"
  },
  suggestionsContainerOpen: {
    // position: "absolute",
    zIndex: 1,
    // marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestion: {
    // display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    // height: theme.spacing(2)
  },
  inputRoot: {
    color: "grey",
    borderRadius: 10
    // width: "100%"
  },
  inputInput: {
    color: "grey",
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    // width: "100%",
    width: 150,
    [theme.breakpoints.up("sm")]: {
      "&:focus": {
        // width: "100%",
        width: 200
      }
    }
  }
});

export default AutoSuggestTextFieldStyles;
