import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import classnames from "classnames";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  Grid,
  // CardHeader,
  // Avatar
} from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import StyledAnchor from "../../../components/StyledAnchor";
import StyledLink from "../../../components/StyledLink";
import { PATHS } from "../../../../routes/Routes";
import DownloadIcon from "../../../components/ExchangeIcons/DownloadIcon";
import FlowJoSoftwareIcon from "../../../components/ExchangeIcons/FlowJoSoftwareIcon";
import SeqGeqSoftwareIcon from "../../../components/ExchangeIcons/SeqGeqSoftwareIcon";

import { getExchangeS3Media } from "../../../../constants/API/ExchangeCleverlyAPI";
import CitationIcon from "../../../components/ExchangeIcons/CitationIcon";
import { getLatestPluginVersionLink, getLatestPluginVersionNumberAndPublishDate } from "../../../../utils/ExchangeUtils";
import ShareIcon from "../../../components/ExchangeIcons/ShareIcon";
import usePluginCardStyle from "./PluginCardStyle";

const icons = {
  'SeqGeq™': SeqGeqSoftwareIcon,
  'FlowJo™': FlowJoSoftwareIcon,
}

const PluginCard = ({ plugin }) => {
  const {
    image,
    versions,
    citation,
    shortDescription,
    cover,
    filter,
    applications,
  } = plugin;

  const [raised, setRaised] = React.useState(false);
  const classes = usePluginCardStyle();
  function handleHover() {
    setRaised(!raised);
  }

  const citationLinkButton = citation ? (
    <Grid item className={classes.downloadButton}>
      <StyledAnchor
        disabled={citation ? false : true}
        href={citation ? citation : ""}
        newTab
      >
        <IconButton
          onClick={() => toast.success("Copied " + plugin.name + " citation")}
          disabled={citation ? false : true}
        >
          <CitationIcon size={30} />
        </IconButton>
      </StyledAnchor>
    </Grid>
  ) : (
      <div />
    );

  const applicationsIcons = () => {
    return applications.map(application => {
      const { name } = application;

      const Icon = icons[name];
      if (!Icon) return null;
      return (
        <Grid item className={classes.downloadButton} key={application.id}>
          <Icon size={50} />
        </Grid>
      )
    })

  };

  const downloadLinkButton = (
    <Grid item className={classes.downloadButton}>
      <StyledAnchor href={versions ? getLatestPluginVersionLink(versions) : ""}>
        <IconButton onClick={() => toast.success("Downloaded " + plugin.name)}>
          <DownloadIcon size={30} />
        </IconButton>
      </StyledAnchor>
    </Grid>
  );
  const imageMedia = image ? (
    <CardMedia
      className={classnames(classes.media, {
        [classes.cover]: cover,
        [classes.contain]: !cover,
        [classes.filter]: filter
      })}
      image={getExchangeS3Media(image)}
    />
  ) : null;

  return (
    <Card
      className={classes.card}
      raised={raised}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {/* <CardHeader
        avatar={
          <Avatar className={classes.avatar}>{plugin.name ? plugin.name[0] : "P"}</Avatar>
        }
        action={
          () => console.log('')
        }
        title={plugin.name ? plugin.name : ""}
        subheader={""}
        className={classes.header}
      /> */}
      <Grid
        direction="row"
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
        container
        className={classes.cardHeader}
      >
        <Grid item>
          <Typography variant="h5">{plugin.name}</Typography>
        </Grid>
        <Grid item>
          <CopyToClipboard
            text={`${window.location.href}plugin/profile?id=${plugin.id}`}
            onCopy={() => toast.info(`Copied ${plugin.name} shareable link`)}
          >
            {/* <Tooltip title="Copy link to clipboard" placement="top"> */}
            <IconButton className={classes.shareButton}>
              <ShareIcon size={30} className={classes.icon} />
            </IconButton>
            {/* </Tooltip> */}
          </CopyToClipboard>
        </Grid>
      </Grid>
      <CardActionArea>
        <StyledLink
          to={{
            pathname: PATHS.PLUGIN.PROFILE,
            search: `?id=${plugin.id}`
          }}
        >
          {imageMedia ? imageMedia : null}
        </StyledLink>
      </CardActionArea>

      <CardContent className={classes.content}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          component="p"
          style={{ marginTop: 10 }}
          className={classes.author}
        >
          {getLatestPluginVersionNumberAndPublishDate(versions)}
        </Typography>
        <Typography
          align="left"
          variant="body1"
          color="textPrimary"
          component="p"
        >
          {shortDescription}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Grid container justifyContent="space-between">
          {citationLinkButton}
          {applicationsIcons()}
          {versions ? downloadLinkButton : null}
        </Grid>
      </CardActions>
    </Card>
  );
};

export default PluginCard;
