import React, { useState } from "react";
import _ from "lodash";
import { Sticky } from "react-sticky";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Switch from "@material-ui/core/Switch";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
// import BreadCrumbLoader from "../../../components/Breadcrumb/BreadCrumbLoader";
// import AutoSuggestTextField from "../../../components/AutoSuggestTextField";
// import { toast } from "react-toastify";
import usePluginFiltersStyles from "./PluginFiltersStyles";
import AutoSuggestTextField from "../../../components/AutoSuggestTextField";
import { Grid, ListItemIcon, MenuItem } from "@material-ui/core";
import { toast } from "react-toastify";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import StyledLink from "../../../components/StyledLink";
import { PATHS } from "../../../../routes/Routes";

const PluginFilters = ({
  plugins,
  categories,
  applications,
  filteredCategories,
  filteredApplications,
  setFilteredPlugins,
  resetFilteredPlugins,
  setFilteredCategories,
  setFilteredApplications
}) => {
  const [openApplications, setOpenApplications] = React.useState(true);
  const [openCategories, setOpenCategories] = React.useState(true);
  const classes = usePluginFiltersStyles();
  const [toastId, setToastId] = useState(null);

  const handleToast = (message, toastType) => {
    if (toastId && toast.isActive(toastId)) {
      toast.update(toastId, {
        render: message,
        type: toastType,
        autoClose: 3000
      });
    } else {
      const newToastId = toast(message, {
        type: toastType,
        autoClose: 3000
      });
      setToastId(newToastId);
    }
  };
  function handleOpenCategories() {
    setOpenCategories(!openCategories);
  }
  const handleOpenApplications = () => {
    setOpenApplications(!openApplications);
  };

  const filterSuggestions = (suggestions, inputValue) => {
    if (inputValue === 0 || !suggestions) {
      setFilteredPlugins(plugins);
    }
    const inputValueLC = inputValue.toLowerCase();

    let endFilter = false;
    const newFilteredPlugins = _.filter(suggestions, suggestion => {
      if (suggestion.name.toLowerCase() === inputValueLC) {
        endFilter = true;
        return endFilter;
      }
      if (endFilter) {
        return false;
      }

      if (_.includes(suggestion.name.toLowerCase(), inputValueLC)) {
        return true;
      }

      if (_.includes(suggestion.description.toLowerCase(), inputValueLC)) {
        return true;
      }

      if (_.includes(suggestion.shortDescription.toLowerCase(), inputValueLC)) {
        return true;
      }

      function toMiniscule(item) {
        return item.name.toLowerCase();
      }

      const authorsLC = _.map(suggestion.authors, toMiniscule);
      if (
        _.find(authorsLC, function(o) {
          return _.includes(o, inputValueLC);
        })
      ) {
        return true;
      }

      const developersLC = _.map(suggestion.developers, toMiniscule);
      if (
        _.find(developersLC, function(o) {
          return _.includes(o, inputValueLC);
        })
      ) {
        return true;
      }

      const categoriesLC = _.map(suggestion.categories, toMiniscule);
      if (
        _.find(categoriesLC, function(o) {
          return _.includes(o, inputValueLC);
        })
      ) {
        return true;
      }

      return false;
    });

    if (!newFilteredPlugins || newFilteredPlugins.length === 0) {
      setFilteredPlugins(plugins);
    } else {
      setFilteredPlugins(newFilteredPlugins);
    }

    return newFilteredPlugins;
  };

  const handleToggleApplications = applicationId => {
    if (!_.includes(filteredApplications, applicationId)) {
      if (filteredApplications.length === applications.length - 1) {
        handleToast(
          "At least one application must be selected",
          toast.TYPE.ERROR
        );
        return;
      }
      setFilteredApplications([...filteredApplications, applicationId]);
    } else {
      setFilteredApplications(
        _.remove(
          filteredApplications,
          filteredId => filteredId !== applicationId
        )
      );
    }
  };
  const isApplicationChecked = applicationId => {
    return !_.includes(filteredApplications, applicationId);
  };

  const handleToggleCategories = categoryId => {
    if (!_.includes(filteredCategories, categoryId)) {
      if (filteredCategories.length === categories.length - 1) {
        handleToast("At least one category must be selected", toast.TYPE.ERROR);
        return;
      }
      setFilteredCategories([...filteredCategories, categoryId]);
    } else {
      setFilteredCategories(
        _.remove(filteredCategories, filteredId => filteredId !== categoryId)
      );
    }
  };
  const isCategoryChecked = categoryId => {
    return !_.includes(filteredCategories, categoryId);
  };

  function customRenderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);
    return (
      <StyledLink
        to={{
          pathname: PATHS.PLUGIN.PROFILE,
          search: `?id=${suggestion.id}`
        }}
      >
        <MenuItem selected={isHighlighted}>
          {_.map(parts, part => (
            <span
              key={part.text}
              style={{
                fontWeight: part.highlight ? 900 : 400,
                color: part.highlight ? "black" : "grey"
              }}
            >
              {part.text}
            </span>
          ))}
        </MenuItem>
      </StyledLink>
    );
  }

  const theme = useTheme();
  const matchesBreakPointSM = useMediaQuery(
    theme.breakpoints.up("xs", "sm", "md")
  );

  const content = (
    <Paper elevation={0} style={{ zIndex: 10000000 }}>
      <AutoSuggestTextField
        items={plugins}
        filterSuggestions={filterSuggestions}
        clearRequested={resetFilteredPlugins}
        customRenderSuggestion={customRenderSuggestion}
      />
      <List>
        <ListItem button onClick={handleOpenApplications}>
          <ListItemText primary="Applications" />
          <ListItemIcon>
            {openApplications ? <GoChevronDown /> : <GoChevronUp />}
          </ListItemIcon>
        </ListItem>
        <Collapse in={openApplications} timeout="auto" unmountOnExit>
          {applications
            ? _.map(applications, application => {
                return (
                  <ListItem
                    key={application.id}
                    button
                    className={classes.nested}
                    onClick={() => handleToggleApplications(application.id)}
                  >
                    <ListItemIcon>
                      <Switch
                        color="primary"
                        // onChange={() =>
                        //   handleToggleApplications(application.id)
                        // }
                        checked={isApplicationChecked(application.id)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={application.name} />
                  </ListItem>
                );
              })
            : null}
        </Collapse>

        <ListItem button onClick={handleOpenCategories}>
          <ListItemText primary="Categories" />
          <ListItemIcon>
            {openCategories ? <GoChevronDown /> : <GoChevronUp />}
          </ListItemIcon>
        </ListItem>
        <Collapse in={openCategories} timeout="auto" unmountOnExit>
          <List>
            {categories
              ? _.map(categories, category => {
                  if (category.pluginCount > 0) {
                    return (
                      <ListItem
                        button
                        key={category.id}
                        onClick={() => handleToggleCategories(category.id)}
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <Switch
                            color="primary"
                            // onChange={() => handleToggleCategories(category.id)}
                            checked={isCategoryChecked(category.id)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={category.name} />
                      </ListItem>
                    );
                  }
                })
              : null}
          </List>
        </Collapse>
      </List>
    </Paper>
  );

  return (
    <Grid className={classes.root} container>
      <Grid xs={12} sm={12} item>
        {matchesBreakPointSM ? (
          <Sticky relative={false}>
            {({ style }) => (
              <div
                style={{
                  ...style,
                  paddingTop: matchesBreakPointSM ? 30 : 0,
                  zIndex: 100000
                  // width: 200,
                  // marginBottom: 50
                }}
              >
                {content}
              </div>
            )}
          </Sticky>
        ) : (
          content
        )}
      </Grid>
    </Grid>
  );
};

export default PluginFilters;
