//* *************_Constants_****************//

//* ***************_Endpoints_******************//
const CATEGORIES = `categories`;
const PLUGINS = `plugins`;
const APPLICATIONS = `applications`;
const SHELL = "shell";
const PRODUCTION = "production";

//* ***************_DevProd_URL_******************//

const getExchangeDomain = () => {
  // const productionURL = `https://${window.location.hostname}/exchange-api/`;
  const productionURL = `${window.location.protocol}//${window.location.hostname}/exchange-api/`;
  const localURL = "http://localhost/exchange-api/";
  if (process.env.NODE_ENV === PRODUCTION) {
    return productionURL;
  } 
  return localURL;
};

export const getExchangeS3Media = path => {
  return `${getExchangeS3BucketURL()}/media${path}`;
};

export const getPluginClapByIdEndpoint = pluginId => {
  return `${getExchangeDomain()}${PLUGINS}/${pluginId}/clap`;
};
export const getPluginByIdEndpoint = pluginId => {
  return `${getExchangeDomain()}${PLUGINS}/${pluginId}/`;
};

export const getFlowJoHeaderFooterEndpoint = () => {
  return `${getExchangeDomain()}${SHELL}/`;
};

export const getExchangeS3BucketURL = path => {
  return `https://flowjowebsiteimages.s3-us-west-2.amazonaws.com`;
};

const appendExchangeDomain = (endpoint, queryParams = "") => {
  return `${getExchangeDomain()}${endpoint}${queryParams}`;
};

export const getPluginCategoriesEndpoint = () => {
  return appendExchangeDomain(CATEGORIES);
};

export const getPluginApplicationsEndpoint = () => {
  return appendExchangeDomain(APPLICATIONS);
};

export const getPluginsWithApplicationEndpoint = id => {
  return appendExchangeDomain(APPLICATIONS, getIdQueryParam(id));
};

export const getPluginsEndpoint = () => {
  return appendExchangeDomain(PLUGINS);
};

const getLimtQueryParam = limit => {
  return `?limit=${limit}`;
};

export const getPluginsWithLimitEndpoint = limit => {
  return appendExchangeDomain(CATEGORIES, getLimtQueryParam(limit));
};

const getFeaturedQueryParam = (featured = true) => {
  return `?featured=${featured}`;
};

export const getFeaturedPluginsEndpoint = (featured = true) => {
  return appendExchangeDomain(PLUGINS, getFeaturedQueryParam(featured));
};

const getCategoryQueryParam = categoryId => {
  return `?category=${categoryId}`;
};

const getIdQueryParam = id => {
  return `?id=${id}`;
};

export const getPluginsWithCategoryEndpoint = categoryId => {
  return appendExchangeDomain(PLUGINS, getCategoryQueryParam(categoryId));
};