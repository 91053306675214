import React from "react";
import shareImage from "../../../resources/images/Share_v1.svg";

const ShareIcon = ({ size = 30 }) => {
  return (
    <img
      src={shareImage}
      alt="Share-Icon"
      style={{
        minWidth: size,
        maxWidth: size,
        maxHeight: size,
        minHeight: size,
        padding: 4
      }}
    />
  );
};

export default ShareIcon;
