import React, { useEffect, useState } from "react";
import Axios from "axios";
import _ from "lodash";

import { Paper, Grid, Typography, Container } from "@material-ui/core";
import { getPluginsWithCategoryEndpoint } from "../../../../constants/API/ExchangeCleverlyAPI";
import Breadcrumb from "../../../components/Breadcrumb";
import PluginCard from "../PluginCard";
import usePluginCategoryStyles from "./PluginCategoryStyles";
import { parseExchangePlugins } from "../../../../utils/ExchangeUtils";

const PluginCategory = ({
  categoryId,
  categoryName,
  category,
  filteredCategoryPlugins,
  getCategories
}) => {
  const classes = usePluginCategoryStyles();
  const [categoryPlugins, setCategoryPlugins] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);

  useEffect(() => {
    if (!filteredCategoryPlugins) {
      Axios.get(getPluginsWithCategoryEndpoint(categoryId)).then(response => {
        if (response.status === 200) {
          const newParsedCategoryPlugins = parseExchangePlugins(response.data);
          setCategoryPlugins(newParsedCategoryPlugins);
        }
      });
    } else {
      setCategoryPlugins(filteredCategoryPlugins);
    }
  }, [categoryId]);

  useEffect(() => {
    if (!category) {
      getCategories();
    } else {
      setCurrentCategory(category);
    }
  }, [category]);

  return (
    <Grid
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      direction="column"
      spacing={10}
      container
      className={classes.root}
    >
      <Grid item className={classes.breadcrumb}>
        <Breadcrumb
          current={
            currentCategory && currentCategory.name
              ? currentCategory.name
              : null
          }
        />
      </Grid>
      <Grid item>
        <Paper elevation={0}>
          <Grid
            direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            // spacing={5}
            container
          >
            <Grid item>
              <Typography align="center" variant="h2">
                {currentCategory && currentCategory.name
                  ? currentCategory.name
                  : null}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center" variant="h6">
                {currentCategory && currentCategory.description
                  ? currentCategory.description
                  : null}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item>
        <Container maxWidth="lg">
          <Grid
            justifyContent="flex-start"
            spacing={5}
            container
            className={classes.content}
          >
            {_.map(categoryPlugins, plugin => {
              return (
                <Grid key={plugin.id} item>
                  <PluginCard plugin={plugin} />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default PluginCategory;
