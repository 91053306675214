import React, { useState, useEffect } from "react";
import Axios from "axios";
import _ from "lodash";

import {
  withStyles,
  Paper,
  Grid,
  CssBaseline,
  Typography,
  Divider,
  Container,
  Button,
  // IconButton
} from "@material-ui/core";

import ProfileStyles from "./ProfileStyles";
import Breadcrumb from "../../../components/Breadcrumb";
import {
  getPluginByIdEndpoint,
  getExchangeS3Media,
  getPluginClapByIdEndpoint
} from "../../../../constants/API/ExchangeCleverlyAPI";
import StyledAnchor from "../../../components/StyledAnchor";
import { GoMarkGithub } from "react-icons/go";
import {
  getLatestPluginVersionNumber,
  getLatestPluginVersionLink,
  parseExchangePlugins,
  getSortedPluginVersions
} from "../../../../utils/ExchangeUtils";
import ClapButton from "react-clap-button";
import { useTheme } from "@material-ui/styles";
import FlowJoSoftwareIcon from "../../../components/ExchangeIcons/FlowJoSoftwareIcon";
import SeqGeqSoftwareIcon from "../../../components/ExchangeIcons/SeqGeqSoftwareIcon";
import { getDayMonthYear } from "../../../../utils/DateUtils";

const icons = {
  'SeqGeq™': SeqGeqSoftwareIcon,
  'FlowJo™': FlowJoSoftwareIcon,
}


const Profile = ({ classes, id, plugin }) => {
  const [currentPlugin, setCurrentPlugin] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    if (id) {
      Axios.get(getPluginByIdEndpoint(id))
        .catch(error => console.log("error", error))
        .then(response => {
          if (response && response.status) {
            if (response.status === 200) {
              const newPlugin = parseExchangePlugins([response.data]);
              setCurrentPlugin(newPlugin[0]);
            }
          }
        });
    } else {
      setCurrentPlugin(plugin);
    }
    window.scrollTo(0, 0);
  }, [id, plugin]);

  const handleClap = () => {
    Axios.get(getPluginClapByIdEndpoint(id)).catch(error =>
      console.log("error", error)
    );
  };

  const latestVersionNumber = currentPlugin && currentPlugin.versions ? getLatestPluginVersionNumber(currentPlugin.versions) : 1.0;

  return (
    <Paper elevation={0} className={classes.root}>
      <CssBaseline />
      <Grid direction="column" container>
        <Grid item>
          <Breadcrumb
            current={
              currentPlugin && currentPlugin.name ? currentPlugin.name : null
            }
          />
        </Grid>
        <Grid item>
          <Container maxWidth="lg">
            {/* Main featured post */}
            <Paper elevation={0} className={classes.mainFeaturedPost}>
              <Grid
                alignContent="center"
                alignItems="center"
                justifyContent="space-between"
                container
              >
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={8}
                  className={classes.mainFeaturedPostContent}
                >
                  <Grid
                    direction="column"
                    alignItems="flex-start"
                    alignContent="flex-start"
                    justifyContent="flex-start"
                    spacing={4}
                    container
                  >
                    <Grid item>
                      <Typography align="left" variant="h2">
                        {currentPlugin && currentPlugin.name
                          ? currentPlugin.name
                          : " "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        component="h1"
                        variant="h4"
                        align="left"
                        gutterBottom
                      >
                        {currentPlugin ? currentPlugin.shortDescription : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  {currentPlugin ? (
                    <img
                      style={{ height: 250 }}
                      src={getExchangeS3Media(currentPlugin.image)}
                      alt="background"
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Paper>
            {/* End main featured post */}
            {/* End sub featured posts */}
            <Paper elevation={0} className={classes.mainContent}>
              <Grid container spacing={5} className={classes.mainGrid}>
                {/* Main content */}
                <Grid item xs={12} md={8}>
                  <Grid direction="column" spacing={5} container>
                    {/* <Uncontrol */}
                    <Grid item>
                      <Typography variant="h6" gutterBottom>
                        Description
                      </Typography>
                      <Divider />
                      <Typography variant="subtitle1" color="inherit" paragraph>
                        {currentPlugin ? currentPlugin.description : ""}
                      </Typography>
                    </Grid>

                  {/*  Bundle plugins */}
                    {currentPlugin && currentPlugin.children && currentPlugin.children.length ? (
                        currentPlugin.children.map(plugin => (
                          <React.Fragment key={plugin.id}>
                            <Divider />
                            <Grid item >
                              <Typography variant="h6" gutterBottom>{plugin.name}</Typography>
                                  <Typography gutterBottom>
                                    {plugin.bundle_description.trim().length ? plugin.bundle_description : plugin.description}
                                  </Typography>

                              <StyledAnchor href={`/exchange#/plugin/profile?id=${plugin.id}`}>
                                <Typography
                                  variant='button'
                                  style={{
                                    fontSize: '1rem',
                                    display: 'inline-block',
                                    margin: '0.8rem 0 0',
                                  }}
                                >
                                  More details here...
                                </Typography>
                              </StyledAnchor>
                            </Grid>
                          </React.Fragment>
                        ))
                    ): null}
                    {/* End Bunble plugins */}

                    {currentPlugin && currentPlugin.authors && currentPlugin.authors.length ? (
                      <>
                        <Divider />
                         <Grid item>
                          <Typography variant="h6" gutterBottom>Algorithm Authors</Typography>
                          {_.map(currentPlugin.authors, author => {
                            return (
                              <Typography key={author.name} gutterBottom>
                                {author.name}
                              </Typography>
                            );
                          })}
                          <Typography variant="h6" gutterBottom>
                            Plugin Developers
                          </Typography>
                          {_.map(currentPlugin.developers, developer => {
                            return (
                              <Typography key={developer.name}>
                                {developer.name}
                              </Typography>
                            );
                          })}
                        </Grid>
                      </>
                    ) : null}

                  {currentPlugin && (currentPlugin.githubLink || currentPlugin.citation) ? (<Divider />) : null}

                  {currentPlugin && (currentPlugin.githubLink || currentPlugin.citation) ? (
                      <Grid item>
                        <Grid spacing={5} direction="row" container>
                          {currentPlugin && currentPlugin.githubLink ? (
                            <Grid item>

                              <StyledAnchor
                                disabled={currentPlugin.githubLink ? false : true}
                                href={
                                  currentPlugin.githubLink
                                    ? currentPlugin.githubLink
                                    : ""
                                }
                                newTab
                              >
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  size="large"
                                >
                                  Github
                                  <GoMarkGithub style={{ width: 30 }} />
                                </Button>
                              </StyledAnchor>
                            </Grid>
                          ) : null}
                          <Grid item>
                            <Grid item>
                              {currentPlugin && currentPlugin.citation ? (
                                <StyledAnchor
                                  disabled={currentPlugin.citation ? false : true}
                                  href={
                                    currentPlugin.citation
                                      ? currentPlugin.citation
                                      : ""
                                  }
                                  newTab
                                >
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="large"
                                  >
                                    Citation
                                  </Button>
                                </StyledAnchor>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ): null}


                    <Divider />
                    <Grid item>
                      <Typography variant="h6" gutterBottom>
                        Applications
                      </Typography>
                      {currentPlugin && currentPlugin.applications && currentPlugin.applications.map(application => {
                        const { name } = application;

                        const Icon = icons[name];
                        if (!Icon) return null;
                        return (
                          <Icon size={50} key={application.id}/>
                        )
                      })}
                    </Grid>
                    <Divider />
                    <Grid item>
                      <Typography variant="h6" gutterBottom>
                        Categories
                      </Typography>
                      {currentPlugin
                        ? _.map(currentPlugin.categories, category => {
                          return (
                            <Typography key={category.id}>
                              {category.name}
                            </Typography>
                          );
                        })
                        : null}
                    </Grid>
                  </Grid>
                </Grid>
                {/* End main content */}
                {/* Sidebar */}
                <Grid item xs={12} md={4}>
                  <Grid
                    direction="column"
                    spacing={2}
                    justifyContent="center"
                    alignContent="center"
                    container
                    className={classes.sidebarAboutBox}
                  >
                    <Grid item>
                      <Typography variant="h6" gutterBottom>
                        {currentPlugin
                          ? `Version ${getLatestPluginVersionNumber(
                            currentPlugin.versions
                          )} `
                          : null}
                      </Typography>
                      {currentPlugin ? (
                        <Typography>
                          +{currentPlugin.numDownloads} researchers are using
                          this tool
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item>
                      <Grid
                        alignContent="center"
                        alignItems="center"
                        direction="row"
                        spacing={3}
                        container
                      >
                        <Grid item>
                          {currentPlugin ? (
                            <StyledAnchor
                              href={getLatestPluginVersionLink(
                                currentPlugin.versions
                              )}
                            >
                              <Button
                                variant="contained"
                                color="secondary"
                                style={{ minWidth: 150, minHeight: 40 }}
                              >
                                <div style={{ color: "white" }}>Download</div>
                              </Button>
                            </StyledAnchor>
                          ) : null}
                        </Grid>
                        <Grid item>
                          {currentPlugin ? (
                              <ClapButton
                                countTotal={currentPlugin.numClaps}
                                onCountChange={handleClap}
                                style={{ width: 30 }}
                                theme={{
                                  primaryColor: theme.palette.primary.main,
                                  secondaryColor: theme.palette.primary.main
                                }}
                              />
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>


                    {/* Bundle */}
                    {currentPlugin && currentPlugin.parents && currentPlugin.parents.length > 0 ? (
                      <>
                        <Grid item >
                          <Grid
                            alignContent="center"
                            alignItems="center"
                            direction="row"
                            spacing={1}
                            container
                          >
                            <Typography variant="h6" gutterBottom >
                              Also available bundled in:
                            </Typography>
                          </Grid>
                        </Grid>

                        {currentPlugin.parents.map(bundle => {
                        return (
                          <Grid item key={bundle.id}>
                            <Grid
                              alignContent="center"
                              alignItems="center"
                              direction="row"
                              spacing={1}
                              container
                            >
                              <Typography variant="subtitle1" gutterBottom align="center" >
                                  { bundle.name }
                              </Typography>
                            </Grid>
                            <Grid
                              alignContent="center"
                              alignItems="center"
                              direction="row"
                              spacing={1}
                              container
                            >
                                <Grid item>
                                <StyledAnchor
                                  href={`/exchange#/plugin/profile?id=${bundle.id}`} >
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      style={{ minWidth: 150, minHeight: 40 }}
                                    >
                                      <div style={{ color: "white" }}>Take me there</div>
                                    </Button>
                                  </StyledAnchor>
                              </Grid>
                              <Grid item>
                                  <StyledAnchor
                                    href={getLatestPluginVersionLink(
                                      bundle.versions
                                    )}
                                  >
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      style={{ minWidth: 150, minHeight: 40 }}
                                    >
                                      <div style={{ color: "white" }}>Download</div>
                                    </Button>
                                  </StyledAnchor>
                              </Grid>
                            </Grid>
                        </Grid>
                        )
                        })}
                      </>): null}
                    {/* end Bundle */}



                    {currentPlugin && currentPlugin.versions && currentPlugin.versions.length > 1 && getSortedPluginVersions(currentPlugin.versions).map((pluginVersion, index) => {
                      const { downloadLink, publishDate, version } = pluginVersion;

                      if (version === latestVersionNumber) return null;

                      return (
                        <React.Fragment key={pluginVersion.version}>

                          {index === 1 && (
                              <>
                                <Divider style={{ marginTop: 20, marginBottom: 10 }}/>
                                <Typography variant="h6" gutterBottom>
                                  Previous Versions
                                </Typography>
                              </>
                          )
                            }
                          <Grid item>
                            <StyledAnchor href={downloadLink}>
                              <Typography
                                variant='button'
                              >
                                {`${version} - ${getDayMonthYear(publishDate)}`}
                              </Typography>
                            </StyledAnchor>
                          </Grid>
                        </React.Fragment>
                      )
                    })}
                  </Grid>
                </Grid>
                {/* End sidebar */}
              </Grid>
            </Paper>
          </Container>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(ProfileStyles)(Profile);
