import React from "react";
import { withStyles, Paper, Typography, Grid } from "@material-ui/core";
import FlowJoExchangeIcon from "../../components/ExchangeIcons/FlowJoExchangeIcon";

const styles = theme => ({
  root: {
    borderRadius: 0,
    height: theme.spacing(56.5),
    maxWidth: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    background:
      "url(https://flowjowebsiteimages.s3-us-west-2.amazonaws.com/media/Plugins/StockPluginImages/FlowJo-Exchange-main-image-overlay.svg)"
  },
  textContainer: {
    color: "white"
  },
  content: {
    height: "100%",
    width: "60%",
    margin: "0 auto"
  }
});

const ExchangeHero = ({ classes }) => {
  return (
    <Paper elevation={0} className={classes.root}>
      <Grid
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        direction="column"
        container
        spacing={6}
        className={classes.content}
      >
        <Grid item>
          <FlowJoExchangeIcon />
        </Grid>
        <Grid item>
          <Typography
            align="center"
            variant="h5"
            className={classes.textContainer}
          >
            <b>
              Plugins to our applications help your research stay ahead of the
              curve. Our industry-leading collaborations help us bring
              informatics innovation to you quickly and intuitively.
            </b>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(ExchangeHero);
