export const getMonthNamesByIndex = () => {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
};

const getDayEnding = (day) => {
  if (day === 1) return 'st';
  else if (day === 2) return 'nd';
  else if (day === 3) return 'rd';
  else if (day === 21) return 'st';
  else if (day === 22) return 'nd';
  else if (day === 23) return 'rd';
  else if (day === 31) return 'st';
  return 'th'
}

export const getDayMonthYear = stringDate => {
  const date = new Date(stringDate.replace(/\s/, 'T'));
  var month = date.getUTCMonth(); //months from 1-12
  var day = date.getUTCDate();
  var year = date.getUTCFullYear();
  const dayEnding = getDayEnding(day);

  return `${getMonthName(month)} ${day}${dayEnding}, ${year}`;
};

export const getMonthName = monthIndex => {
  return getMonthNamesByIndex()[monthIndex];
};