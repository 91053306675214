import { connect } from "react-redux";
import queryString from "query-string";
import PluginCategory from "./PluginCategory";
import { getPluginsWithCategory, getCategories } from "../../../../store/reducers/exchange";
import { getItemById } from "../../../../utils/ExchangeUtils";

const mapStateToProps = ({ router, exchange: { categories } }) => {
  const { location } = router;
  const queryParams = queryString.parse(location.search);
  const categoryId = Number.parseInt(queryParams.id);
  const category = getItemById(categories, categoryId);
  return {
    categoryId,
    category
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPluginsWithCategory: () => dispatch(getPluginsWithCategory()),
    getCategories: () => dispatch(getCategories())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PluginCategory);
