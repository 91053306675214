import React, { useLayoutEffect, useState } from "react";
import axios from "axios";
import map from "lodash/map";

import { ToastContainer, toast } from "react-toastify";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";

import ExchangeHeader from "./pages/Exchange/ExchangeHeader";
import "../../node_modules/jquery/dist/jquery.js";
import "./Application.css";
import { getFlowJoHeaderFooterEndpoint } from "../constants/API/ExchangeCleverlyAPI";
import ExchangeFooter from "./pages/Exchange/ExchangeFooter";

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

const useStyles = makeStyles(theme => ({
  // "@global": {
  //   "*::-webkit-scrollbar": {
  //     width: "0.25em"
  //   },
  //   "*::-webkit-scrollbar-track": {
  //     "-webkit-box-shadow": "inset 0 0 1px rgba(0,0,0,0.0001)"
  //   },
  //   "*::-webkit-scrollbar-thumb": {
  //     backgroundColor: "rgba(0,0,0,0.05)",
  //     outline: "1px solid slategrey"
  //   },
  //   overflow: "hidden"
  // },
  root: {
    // minWidth: "100vw"
    // minHeight: "100vh"
  },
  appBarSpacer: {
    // [theme.breakpoints.between("md", "lg", "xl")]: {
    //   ...theme.mixins.toolbar,
    //   paddingTop: theme.spacing(22) + 5.3
    // },
    // [theme.breakpoints.between("sm")]: {
    //   // ...theme.mixins.toolbar
    //   paddingTop: theme.spacing(22) + 5.3
    // }
    // margin: 0
  },
  headerMobileSpacer: {
    // top: -theme.spacing.unit * 4
  },
  content: {},
  children: {}
}));

const App = ({ children }) => {
  const [header, setHeader] = useState(null);
  const [footer, setFooter] = useState(null);
  const [cssFiles, setCssFiles] = useState(null);
  const [jsFiles, setJsFiles] = useState(null);
  const classes = useStyles();

  useLayoutEffect(() => {
    getHeaderFooter();
  }, []);

  const getHeaderFooter = () => {
    axios
      .get(getFlowJoHeaderFooterEndpoint())
      .catch(error => console.log("ERR", error))
      .then(response => {
        if (response) {
          const { data } = response;
          if (data.markup) {
            const { markup, bodyClasses } = data;

            if (bodyClasses && bodyClasses[0]) {
              const bodyClass = bodyClasses[0];
              document.body.classList.add(bodyClass);
            }

            if (data.assets) {
              const { assets } = data;
              // console.log(assets);
              if (assets.css) {
                const { css } = assets;
                const newCssFiles = map(css, cssFile => {
                  return cssFile;
                });
                setCssFiles(newCssFiles);
              }
              if (assets.js) {
                const { js } = assets;
                const newJsFiles = map(js, jsFile => {
                  return jsFile;
                });
                setJsFiles(newJsFiles);
              }
            }

            if (markup.footer && markup.header) {
              const { header, footer } = markup;
              setHeader(header);
              setFooter(footer);
            }
          }
        }
      });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.appBarSpacer} />
      <div className={classes.content}>
        <ExchangeHeader css={cssFiles} js={jsFiles} html={header} />
        {children}
      </div>
      <ExchangeFooter html={footer} />
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
    </div>
  );
};

export default App;
