import { exchangeSagas } from "./reducers/exchange";

const sagas = [exchangeSagas];

export const initSagas = sagaMiddleware => {
  sagas.forEach(sagaMiddleware.run.bind(sagaMiddleware));
};

// export const initSagas = sagaMiddleware =>
//   sagas.forEach(saga => {
//     sagaMiddleware.run(saga, getFirebase);
//   });
