import {
  makeStyles
} from "@material-ui/core/styles";

const multiplier = 1.27;
const baseHeight = 45.5;
const usePluginCardStyle = makeStyles(theme => ({
  card: {
    minWidth: theme.spacing(baseHeight),
    maxWidth: theme.spacing(baseHeight),
    minHeight: theme.spacing(baseHeight * multiplier),
    maxHeight: theme.spacing(baseHeight * multiplier)
  },
  media: {
    minHeight: theme.spacing(baseHeight / 1.6)
    // margin: theme.spacing(1)
  },
  content: {
    minHeight: theme.spacing(baseHeight / 3.6),
    maxHeight: theme.spacing(baseHeight / 3.6),
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(4)
  },
  contain: {
    backgroundSize: "contain"
  },
  cover: {
    backgroundSize: "cover"
  },
  filter: {
    filter: "grayscale(100%)"
  },
  actions: {
    display: "flex",
    padding: theme.spacing(1)
  },
  margin: {
    // margin: theme.spacing(1)
  },
  rightIcon: {
    paddingLeft: theme.spacing(1)
  },
  cardHeader: {
    // maxHeight: 40,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1) / 4,
    paddingBottom: theme.spacing(1) / 8
  }
}));
export default usePluginCardStyle;