import React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import Root from "./features/Root.jsx";
import { configureStore, history } from "./store/configureStore";

const reduxStore = configureStore();

render(
  <AppContainer>
    <Root store={reduxStore} history={history} />
  </AppContainer>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept("./features/Root", () => {
    // eslint-disable-next-line global-require
    const NextRoot = require("./features/Root").default;
    render(
      <AppContainer>
        <NextRoot store={reduxStore} history={history} />
      </AppContainer>,
      document.getElementById("root")
    );
  });
}
