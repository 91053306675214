import React, { useEffect } from "react";
import { StickyContainer } from "react-sticky";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { CssBaseline, Container } from "@material-ui/core";
// import { CssBaseline, Container, Button, AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
// import { Close } from '@material-ui/icons'

import ExchangeHero from "./ExchangeHero";
import PluginCard from "../plugin/PluginCard";
import PluginFilters from "../plugin/PluginFilters";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  content: {
    padding: theme.spacing(4),
    minWidth: "100%"
  },
  plugins: {
    paddingTop: theme.spacing(4)
  }
}));

const Home = ({
  getPlugins,
  getCategories,
  items,
  getApplications,
  resetFilteredPlugins,
  getFeaturedPlugins,
  resetFilteredCategories
}) => {
  const classes = useStyles();

  useEffect(() => {
    getPlugins();
    getFeaturedPlugins();
    getCategories();
    getApplications();
    resetFilteredPlugins();
    resetFilteredCategories();
  }, []);

  // const sortTypes = ["Downloads", "A-Z", "Release Date", "Claps"];
  // const [sortBy, setSortBy] = useState(sortTypes[0]);

  // const handleSetSortBy = sortType => {
  //   setSortBy(sortType);
  // };

  const getItems = () => {
    // switch (sortBy) {
    //   case sortTypes[0]:
    //     return _.sortBy(items, ["numDownloads", "asc"]);
    //   case sortTypes[1]:
    //     return _.sortBy(items, ["name", "asc"]);
    //   case sortTypes[2]:
    //     return _.sortBy(items, ["created_at", "asc"]);
    //   case sortTypes[3]:
    //     return _.sortBy(items, ["numClaps", "asc"]);
    //   default:
    return items;
    // }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <ExchangeHero />
      {/* <Toolbar style={{ flexGrow: 1 }}>
        <Grid direction='row' alignItems='center' justify='space-between' container>
          <Grid item>
            <Typography variant="h6">
              Published
          </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              News
          </Typography>
          </Grid>
          <Grid item>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar> */}
      <StickyContainer>
        <Container maxWidth="lg" className={classes.content}>
          <Grid spacing={4} container>
            <Grid xs={12} sm={12} md={3} lg={2} item>
              <PluginFilters />
            </Grid>
            <Grid xs={12} sm={12} md={9} lg={10} item>
              <Grid
                direction="column"
                alignItems="center"
                container
                className={classes.plugins}
              >
                <Grid item>
                  <Grid
                    alignItems="flex-start"
                    alignContent="flex-start"
                    spacing={2}
                    justifyContent="center"
                    container
                  >
                    {/* <Grid xs={12} md={12} lg={12} xl={12} item>
                      <Grid
                        spacing={2}
                        alignContent="center"
                        alignItems="center"
                        direction="row"
                        container
                      >
                        <Grid item>
                          <Typography variant="h4">Featured</Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => toggleShowFeaturedPlugins()}
                          >
                            {showFeaturedPlugins ? (
                              <GoChevronUp />
                            ) : (
                              <GoChevronDown />
                            )}
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider style={{ marginTop: 5 }} />
                    </Grid> */}
                    {/* <Grid item>
                      <Collapse in={showFeaturedPlugins}>
                        <Grid
                          alignItems="flex-start"
                          alignContent="flex-start"
                          spacing={8}
                          justifyContent="center"
                          container
                        >
                          {_.map(featuredPlugins, plugin => {
                            return (
                              <Grid key={plugin.id} item>
                                <PluginCard plugin={plugin} />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Collapse>
                    </Grid> */}

                    {/* <Grid xs={12} md={12} lg={12} xl={12} item>
                      <Grid
                        spacing={2}
                        alignContent="center"
                        alignItems="center"
                        direction="row"
                        container
                      >
                        <Grid item>
                          <Typography variant="h4">Plugins</Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            style={{ textTransform: "none" }}
                            color="primary"
                            onClick={() => setSortByPopular(!sortByPopular)}
                          >
                            Popular
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            style={{ textTransform: "none" }}
                            color="primary"
                            onClick={() => toggleShowFeaturedPlugins()}
                          >
                            A-Z
                          </Button>
                        </Grid>
                      </Grid>
                      <Divider style={{ marginTop: 5 }} />
                    </Grid> */}

                    <Grid item>
                      <Grid
                        alignItems="flex-start"
                        alignContent="flex-start"
                        spacing={8}
                        justifyContent="center"
                        container
                      >
                        {_.map(getItems(), plugin => {
                          return (
                            <Grid key={plugin.id} item>
                              <PluginCard plugin={plugin} />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </StickyContainer>
    </React.Fragment>
  );
};

export default Home;
