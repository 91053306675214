///////////////////////////////////////////////////////////////////////////////
import { takeLatest, call, all, put } from "redux-saga/effects";
import Axios from "axios";
import _ from "lodash";

import {
  getPluginsEndpoint,
  getPluginCategoriesEndpoint,
  getFeaturedPluginsEndpoint,
  getPluginsWithCategoryEndpoint,
  getPluginApplicationsEndpoint
} from "../../constants/API/ExchangeCleverlyAPI";
import { createAsyncTypes } from "../../utils/sagas";
import { parseExchangePlugins } from "../../utils/ExchangeUtils";

///////////////////////////////////////////////////////////////////////////////
export const FETCH_PLUGINS_ASYNC = createAsyncTypes("FETCH_PLUGINS_ASYNC");
export const FETCH_PLUGINS_WITH_CATEGORY_ASYNC = createAsyncTypes(
  "FETCH_PLUGINS_WITH_CATEGORY_ASYNC"
);
export const FETCH_FEATURED_PLUGINS_ASYNC = createAsyncTypes(
  "FETCH_FEATURED_PLUGINS_ASYNC"
);
export const FETCH_CATEGORIES_ASYNC = createAsyncTypes(
  "FETCH_CATEGORIES_ASYNC"
);
export const FETCH_APPLICATIONS_ASYNC = createAsyncTypes(
  "FETCH_APPLICATIONS_ASYNC"
);
////////////////////////////////////
const SET_CATEGORIES = "SET_CATEGORIES";
const SET_PLUGINS = "SET_PLUGINS";

///////////////////////////////////////////////////////////////////////////////
export const setCategories = categories => dispatch => {
  return dispatch({ type: SET_CATEGORIES, payload: categories });
};
export const setPlugins = plugins => dispatch => {
  return dispatch({ type: SET_PLUGINS, payload: plugins });
};
///////////////////////////////////////////////////////////////////////////////

export const getPlugins = () => dispatch => {
  return dispatch({
    type: FETCH_PLUGINS_ASYNC.PENDING
  });
};

export const getPluginsWithCategory = categoryId => dispatch => {
  return dispatch({
    type: FETCH_PLUGINS_WITH_CATEGORY_ASYNC.PENDING,
    payload: categoryId
  });
};

export const getFeaturedPlugins = () => dispatch => {
  return dispatch({
    type: FETCH_FEATURED_PLUGINS_ASYNC.PENDING
  });
};

///////////////////////////////////////////////////////////////////////////////

const fetchPlugins = () => {
  return Axios.get(getPluginsEndpoint());
};

const fetchFeaturedPlugins = () => {
  return Axios.get(getFeaturedPluginsEndpoint());
};

const fetchPluginsWithCategory = category => {
  return Axios.get(getPluginsWithCategoryEndpoint(category));
};

export function* fetchPluginsAsync() {
  try {
    const response = yield call(fetchPlugins);
    if (response) {
      const newPlugins = response.data;
      const sortedNewRefactoredPlugins = parseExchangePlugins(newPlugins);
      yield put({
        type: FETCH_PLUGINS_ASYNC.SUCCESS,
        payload: sortedNewRefactoredPlugins
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_PLUGINS_ASYNC.ERROR,
      payload: "Error fetching plugins"
    });
  }
}

export function* fetchFeaturedPluginsAsync() {
  try {
    const response = yield call(fetchFeaturedPlugins);
    if (response) {
      const newPlugins = response.data;
      const sortedNewRefactoredPlugins = parseExchangePlugins(newPlugins);
      yield put({
        type: FETCH_FEATURED_PLUGINS_ASYNC.SUCCESS,
        payload: sortedNewRefactoredPlugins
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_FEATURED_PLUGINS_ASYNC.ERROR,
      payload: "Error fetching featured plugins"
    });
  }
}

export function* fetchPluginsWithCategoryAsync({ payload }) {
  try {
    const categoryId = payload;
    const response = yield call(fetchPluginsWithCategory, categoryId);
    if (response) {
      const newPlugins = response.data;
      const sortedNewRefactoredPlugins = parseExchangePlugins(newPlugins);
      yield put({
        type: FETCH_PLUGINS_ASYNC.SUCCESS,
        payload: { plugins: sortedNewRefactoredPlugins, categoryId }
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_PLUGINS_ASYNC.ERROR,
      payload: "Error fetching plugins"
    });
  }
}
///////////////////////////////////////////////////////////////////////////////

export const getApplications = () => dispatch => {
  return dispatch({
    type: FETCH_APPLICATIONS_ASYNC.PENDING
  });
};

///////////////////////////////////////////////////////////////////////////////
const fetchApplications = () => {
  return Axios.get(getPluginApplicationsEndpoint());
};

////////////////////////////////////

export function* fetchApplicationsAsync() {
  try {
    const response = yield call(fetchApplications);
    const { data } = response;
    if (data) {
      yield put({
        type: FETCH_APPLICATIONS_ASYNC.SUCCESS,
        payload: data
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_APPLICATIONS_ASYNC.ERROR,
      payload: "Error fetching categories"
    });
  }
}

///////////////////////////////////////////////////////////////////////////////

export const getCategories = () => dispatch => {
  return dispatch({
    type: FETCH_CATEGORIES_ASYNC.PENDING
  });
};

///////////////////////////////////////////////////////////////////////////////
const fetchCategories = () => {
  return Axios.get(getPluginCategoriesEndpoint());
};

////////////////////////////////////

export function* fetchCategoriesAsync() {
  try {
    const response = yield call(fetchCategories);
    const { data } = response;
    if (data) {
      const sortedCategories = _.orderBy(data, ["pluginCount"], ["desc"]); // Use Lodash to sort array by 'name'
      yield put({
        type: FETCH_CATEGORIES_ASYNC.SUCCESS,
        payload: sortedCategories
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_CATEGORIES_ASYNC.ERROR,
      payload: "Error fetching categories"
    });
  }
}

///////////////////////////////////////////////////////////////////////////////
const initialState = {
  applications: null,
  fetchingApplications: false,
  fetchingApplicationsError: null,
  ////////////////////////////////////
  categories: null,
  fetchingCategories: false,
  fetchingCategoriesError: null,
  ////////////////////////////////////
  plugins: null,
  fetchingPlugins: false,
  fetchingPluginsError: null,
  ////////////////////////////////////
  featuredPlugins: null,
  fetchingFeaturedPlugins: false,
  fetchingFeaturedPluginsError: null
};
////////////////////////////////////

const exchange = (state = initialState, action) => {
  switch (action.type) {
    ///////////////////////////////////////////////////////////////////////////////
    case FETCH_PLUGINS_ASYNC.PENDING:
      return {
        ...state,
        fetchingPlugins: true,
        fetchingPluginsError: null
      };
    case FETCH_PLUGINS_ASYNC.SUCCESS:
      return {
        ...state,
        fetchingPlugins: false,
        plugins: action.payload,
        fetchingPluginsError: null
      };
    case FETCH_PLUGINS_ASYNC.ERROR:
      return {
        ...state,
        fetchingPlugins: false,
        fetchingPluginsError: action.payload
      };
    ///////////////////////////////////////////////////////////////////////////////
    case FETCH_FEATURED_PLUGINS_ASYNC.PENDING:
      return {
        ...state,
        fetchingFeaturedPlugins: true,
        fetchingFeaturedPluginsError: null
      };
    case FETCH_FEATURED_PLUGINS_ASYNC.SUCCESS:
      return {
        ...state,
        fetchingPlugins: false,
        featuredPlugins: action.payload,
        fetchingFeaturedPluginsError: null
      };
    case FETCH_FEATURED_PLUGINS_ASYNC.ERROR:
      return {
        ...state,
        fetchingFeaturedPlugins: false,
        fetchingFeaturedPluginsError: action.payload
      };
    ///////////////////////////////////////////////////////////////////////////////
    case FETCH_CATEGORIES_ASYNC.PENDING:
      return {
        ...state,
        fetchingCategories: true,
        fetchingCategoriesError: null
      };
    case FETCH_CATEGORIES_ASYNC.SUCCESS:
      return {
        ...state,
        fetchingCategories: false,
        categories: action.payload,
        fetchingCategoriesError: null
      };
    case FETCH_CATEGORIES_ASYNC.ERROR:
      return {
        ...state,
        fetchingCategories: false,
        fetchingCategoriesError: action.payload
      };
    ///////////////////////////////////////////////////////////////////////////////
    case FETCH_APPLICATIONS_ASYNC.PENDING:
      return {
        ...state,
        fetchingApplications: true,
        fetchingApplicationsError: null
      };
    case FETCH_APPLICATIONS_ASYNC.SUCCESS:
      return {
        ...state,
        fetchingApplications: false,
        applications: action.payload,
        fetchingApplicationsError: null
      };
    case FETCH_APPLICATIONS_ASYNC.ERROR:
      return {
        ...state,
        fetchingApplications: false,
        fetchingApplicationsError: action.payload
      };
    default:
      return state;
  }
};
///////////////////////////////////////////////////////////////////////////////
export function* exchangeSagas() {
  yield all([
    takeLatest(FETCH_PLUGINS_ASYNC.PENDING, fetchPluginsAsync),
    takeLatest(FETCH_CATEGORIES_ASYNC.PENDING, fetchCategoriesAsync),
    takeLatest(FETCH_APPLICATIONS_ASYNC.PENDING, fetchApplicationsAsync),
    takeLatest(FETCH_FEATURED_PLUGINS_ASYNC.PENDING, fetchFeaturedPluginsAsync),
    takeLatest(
      FETCH_PLUGINS_WITH_CATEGORY_ASYNC.PENDING,
      fetchPluginsWithCategoryAsync
    )
  ]);
}
///////////////////////////////////////////////////////////////////////////////

export default exchange;
///////////////////////////////////////////////////////////////////////////////
