import React from "react";
import downloadImage from "../../../resources/images/Download_v2.svg";

const DownloadIcon = ({ size = 30 }) => {
  return (
    <img
      src={downloadImage}
      alt="Download-Icon"
      style={{
        minWidth: size,
        maxWidth: size,
        maxHeight: size,
        minHeight: size,
        padding: 4

      }}
    />
  );
};

export default DownloadIcon;
