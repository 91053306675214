const PluginApplicationStyles = theme => ({
    root: {
      margin: theme.spacing(2),
      padding: theme.spacing(2)
    },
    title: {
      // transform: "rotate(0deg)",
      // [theme.breakpoints.up("md", "lg")]: {
      //   transform: "rotate(-90deg)"
      // }
    },
    titleItem: {
      maxWidth: theme.spacing(4)
    },
    fullWidth: {
      // width: "100%"
    },
    pluginCard: {
      // width: "100%",
      // margin: theme.spacing.unit * 2
    },
    grid: {
      padding: theme.spacing(2)
    },
    breadcrumb: {
      width: "100%"
    }
  });
  
  export default PluginApplicationStyles;
  