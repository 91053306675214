import { call, fork, take } from "redux-saga/effects";

///////////////////
// ActionHelpers //
///////////////////
const asyncTypes = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

export const createAsyncTypes = typeString =>
  Object.values(asyncTypes).reduce((acc, curr) => {
    acc[curr] = `@@reactReduxFlowJo/${typeString}_${curr}`;
    return acc;
  }, {});
export const createAction = (type, payload = {}) => ({
  type,
  ...payload
});
///////////////////
// createReducer //
///////////////////
export const createReducer = (initialState, handlers) => (
  state = initialState,
  action
) =>
  handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action)
    : state;

export function* takeFirst(pattern, saga, ...args) {
  const task = yield fork(function*() {
    while (true) {
      const action = yield take(pattern);
      yield call(saga, ...args.concat(action));
    }
  });
  return task;
}
