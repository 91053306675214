import { connect } from "react-redux";
import queryString from "query-string";
import PluginApplication from "./PluginApplication";
import { getApplications } from "../../../../store/reducers/exchange";
import { getItemById } from "../../../../utils/ExchangeUtils";

const mapStateToProps = ({ router, exchange: { applications } }) => {
  const { location } = router;
  const queryParams = queryString.parse(location.search);
  const applicationId = Number.parseInt(queryParams.id);
  const application = getItemById(applications, applicationId);
  return {
    applicationId,
    application
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getApplications: () => dispatch(getApplications())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PluginApplication);
