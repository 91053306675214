import { makeStyles } from "@material-ui/core/styles";

const usePluginFiltersStyles = makeStyles(theme => ({
  root: {
    // display: "flex",
    // minWidth: 200
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  icon: {
    maxHeight: theme.spacing(4),
    maxWidth: theme.spacing(4)
  }
}));

export default usePluginFiltersStyles;
