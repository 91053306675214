import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import _ from 'lodash'

import { withStyles, Paper, Grid, Typography } from '@material-ui/core'
import PluginApplicationStyles from './PluginApplicationStyles'
import { getPluginsWithApplicationEndpoint } from '../../../../constants/API/ExchangeCleverlyAPI'
import Breadcrumb from '../../../components/Breadcrumb'
import PluginCard from '../PluginCard'
import { parseExchangePlugins } from '../../../../utils/ExchangeUtils'

const PluginApplication = ({
    classes,
    applicationId,
    applicationName,
    application,
    filteredApplicationPlugins,
    getApplications,
}) => {
    const [aplicationPlugins, setApplicationPlugins] = useState(null)
    const [currentApplication, setCurrentApplication] = useState(null)

    useEffect(() => {
        if (!filteredApplicationPlugins) {
            Axios.get(getPluginsWithApplicationEndpoint(applicationId)).then((response) => {
                if (response.status === 200) {
                    const newParsedApplicationPlugins = parseExchangePlugins(response.data)
                    setApplicationPlugins(newParsedApplicationPlugins)
                }
            })
        } else {
            setApplicationPlugins(filteredApplicationPlugins)
        }
    }, [applicationId])

    useEffect(() => {
        if (!application) {
            getApplications()
        } else {
            setCurrentApplication(application)
        }
    }, [application])

    return (
        <Paper elevation={0} className={classes.root}>
            <Grid
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                direction="column"
                container
            >
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item className={classes.breadcrumb}>
                    <Breadcrumb
                        current={
                            currentApplication && currentApplication.name
                                ? currentApplication.name
                                : null
                        }
                    />{' '}
                </Grid>{' '}
                <Grid item>
                    <Paper
                        elevation={0}
                        style={{
                            padding: 60,
                        }}
                    >
                        <Grid
                            direction="column"
                            alignItems="center"
                            alignContent="center"
                            justifyContent="center"
                            spacing={5}
                            container
                        >
                            <Grid item>
                                <Typography align="center" variant="h2">
                                    {' '}
                                    {currentApplication && currentApplication.name
                                        ? currentApplication.name
                                        : null}{' '}
                                </Typography>{' '}
                            </Grid>{' '}
                            <Grid item>
                                <Typography align="center" variant="h6">
                                    {' '}
                                    {currentApplication && currentApplication.description
                                        ? currentApplication.description
                                        : null}{' '}
                                </Typography>{' '}
                            </Grid>{' '}
                        </Grid>{' '}
                    </Paper>{' '}
                </Grid>{' '}
                <Grid
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                    spacing={10}
                    container
                    className={classes.grid}
                >
                    {_.map(aplicationPlugins, (plugin) => {
                        return (
                            <Grid key={plugin.id} item>
                                <PluginCard plugin={plugin} />{' '}
                            </Grid>
                        )
                    })}{' '}
                </Grid>{' '}
            </Grid>{' '}
        </Paper>
    )
}

export default withStyles(PluginApplicationStyles)(PluginApplication)
