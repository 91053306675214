import React from 'react'
import { connect } from "react-redux";
import { Button, Paper, Grid, Typography } from "@material-ui/core";
import Breadcrumb from "../../components/Breadcrumb";

const _JarsRedirect = (router) => {
  const pathname = router.location.pathname;
  const buttonText = pathname.includes('.zip') | pathname.includes('.jar') ? 'Download File' : 'Redirect';
  const filename = pathname.replace(/^.*[\\/]/, '');
  const path = `https://fjinstallers.s3-us-west-2.amazonaws.com/Plugins/${filename}`;

  return (
    <Paper>
      <Breadcrumb
        current={
          'file'
        }
      />
      <Grid alignContent='center' direction="column" container style={{ minHeight: 500, height: '100%' }}>
        <Grid item >
          <Paper elevation={4} style={{ padding: 40 }}>
            <Grid alignContent='center' alignItems="center" direction="column" container>
              <Grid itemstyle={{ paddingBottom: 20 }}>
                <Typography variant='h4' style={{ paddingBottom: 20 }}>
                  FlowJo Exchange Assets
            </Typography>
              </Grid>
              <Grid item style={{ paddingBottom: 20 }}>
                <Button>
                  <a href={path}>{buttonText}</a>
                </Button>
              </Grid>
              <Grid item>
                <Typography variant='caption'>
                  {path}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  )
}


const mapStateToProps = ({ router }) => {


  return {
    router
  };
};

export default connect(mapStateToProps)(_JarsRedirect);
