import React from "react";
import FlowJoSoftware from "../../../resources/FlowJoSoftwareIcon.svg";

const FlowJoSoftwareIcon = ({ size = 50 }) => {
  return (
    <img
      src={FlowJoSoftware}
      alt="FlowJo-Icon"
      style={{
        minWidth: size,
        maxWidth: size,
        maxHeight: size,
        minHeight: size,
        padding: 4
      }}
    />
  );
};

export default FlowJoSoftwareIcon;
