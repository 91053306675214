import { connect } from "react-redux";
import Exchange from "./Exchange";
import {
  getPlugins,
  getCategories,
  getApplications,
  getFeaturedPlugins
} from "../../../store/reducers/exchange";
import {
  resetFilteredPlugins,
  toggleShowFeaturedPlugins,
  resetFilteredCategories
} from "../../../store/reducers/filtered";

const mapStateToProps = ({
  filtered: { filteredPlugins, showFeaturedPlugins },
  exchange: { plugins, categories, applications, featuredPlugins }
}) => {
  const items =
    filteredPlugins && filteredPlugins.length > 0 ? filteredPlugins : plugins;
  return {
    items,
    categories,
    applications,
    featuredPlugins,
    showFeaturedPlugins
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPlugins: () => dispatch(getPlugins()),
    getCategories: () => dispatch(getCategories()),
    getApplications: () => dispatch(getApplications()),
    resetFilteredPlugins: () => dispatch(resetFilteredPlugins()),
    getFeaturedPlugins: () => dispatch(getFeaturedPlugins()),
    toggleShowFeaturedPlugins: () => dispatch(toggleShowFeaturedPlugins()),
    resetFilteredCategories: () => dispatch(resetFilteredCategories())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Exchange);
