import React from "react";
import map from "lodash/map";
import {
  Helmet
} from "react-helmet";
import ReactHtmlParser from "react-html-parser";


const ExchangeHeader = ({
  css,
  js,
  html
}) => {
  const [loaded, setLoaded] = React.useState(false);

  const handleScriptInject = ({
    scriptTags
  }) => {
    if (!scriptTags) {
      setLoaded(true);
    }
  }

  return ( <
      div >
      <
      Helmet onChangeClientState = {
        (newState, addedTags) => handleScriptInject(addedTags)
      } > {
        loaded && js ?
        map(js, (url, index) => {
          return <script key = {
            index
          }
          src = {
            url
          }
          />;
        }) : null
      } {
        loaded && css ?
          map(css, (url, index) => {
            return <link key = {
              index
            }
            href = {
              url
            }
            rel = "stylesheet" / > ;
          }) :
          null
      } <
      /Helmet> {
      loaded && html && css && js ? ReactHtmlParser(html) : null
    } <
    /div>
);
};

export default ExchangeHeader;