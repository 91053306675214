import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { historyPush } from "../../../store/configureStore";

const useStyles = makeStyles(theme => ({
  link: {
    // textDecoration: "none",
    // color: "inherit"
    color: theme.palette.primary.main
  }
}));

const StyledLink = ({ to, children }) => {
  const classes = useStyles();
  const handlePush = () => {
    historyPush(to);
  };
  return (
    <div
      // color="secondary"
      // variant="text"
      // size="large"
      onClick={handlePush}
      className={classes.link}
    >
      {children}
    </div>
  );
};

export default StyledLink;
