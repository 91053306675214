import React from "react";
import { Switch, Route } from "react-router";
import { MuiThemeProvider } from "@material-ui/core/styles";

import App from "../features/App";
import AppTheme from "../constants/Style/AppTheme";
import Exchange from "../features/pages/Exchange";
import PluginProfile from "../features/pages/plugin/PluginProfile";
import PluginCategory from "../features/pages/plugin/PluginCategory";
import PluginApplication from "../features/pages/plugin/PluginApplication";
import JarsRedirect from '../features/pages/jars';

export const PATHS = {
  EXCHANGE: "/",
  PLUGIN: {
    PROFILE: "/plugin/profile",
    CATEGORY: "/plugin/category",
    APPLICATION: "/plugin/application"
  },
  JARS: '/jars',
};

export default () => (
  <MuiThemeProvider theme={AppTheme}>
    <App>
      <Switch>
        <Route exact path={PATHS.EXCHANGE} component={Exchange} />
        <Route exact path={PATHS.PLUGIN.PROFILE} component={PluginProfile} />
        <Route exact path={PATHS.PLUGIN.CATEGORY} component={PluginCategory} />
        <Route exact path={PATHS.PLUGIN.APPLICATION} component={PluginApplication} />
        <Route path={PATHS.JARS} component={JarsRedirect} />
      </Switch>
    </App>
  </MuiThemeProvider>
);

// const HandleJars = () => {

// }