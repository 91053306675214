// @flow
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import exchange from "./exchange";
import filtered from "./filtered";

export default function createRootReducer(history: History) {
  return combineReducers({
    router: connectRouter(history),
    exchange,
    filtered
  });
}
