import React from "react";
import SeqGeqSoftware from "../../../resources/SeqGeqSoftwareIcon.svg";

const SeqGeqSoftwareIcon = ({ size = 50 }) => {
  return (
    <img
      src={SeqGeqSoftware}
      alt="SeqGeq-Icon"
      style={{
        minWidth: size,
        maxWidth: size,
        maxHeight: size,
        minHeight: size,
        padding: 4
      }}
    />
  );
};

export default SeqGeqSoftwareIcon;
