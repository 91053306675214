import React from "react";
import ContentLoader from "react-content-loader";

const BreadCrumbLoader = () => {
  return (
    <ContentLoader
      height={20}
      width={75}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      style={{ minWidth: 100, minHeight: 20 }}
    >
      <rect x="321" y="446" rx="0" ry="0" width="0" height="0" />
      <circle cx="274" cy="337" r="25" />
      <rect x="2" y="3" rx="4" ry="4" width="71" height="13" />
    </ContentLoader>
  );
};

export default BreadCrumbLoader;
