import {
  connect
} from "react-redux";
import queryString from "query-string";
import Profile from "./Profile";
import {
  getItemById
} from "../../../../utils/ExchangeUtils";

const mapStateToProps = ({
  router,
  exchange: {
    plugins
  }
}) => {
  const {
    location
  } = router;
  const queryParams = queryString.parse(location.search);
  const id = Number.parseInt(queryParams.id);
  const plugin = id ? getItemById(plugins, id) : null;
  return {
    id,
    plugin
  };
};

export default connect(mapStateToProps)(Profile);